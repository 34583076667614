import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import { getCookeiDetail } from "../utils/helper";
import ApiServices from "../utils/ApiServices";
import { useParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import { useToasts } from 'react-toast-notifications';
import { PencilSquare, Heart, HeartFill ,ArrowLeft} from 'react-bootstrap-icons';
import { getUserToken } from "../utils/helper";

const Feed = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isAvailable, setIsAvailable] = useState(false);
  const [postData, setPostData] = useState();
  const [comment, setComment] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [replyAll, setReplyAll] = useState([]);
  const [mode, setMode] = useState('');
  const data = getCookeiDetail("astrologer_data")
  const[loading,setLoading]=useState(false)
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (!params) navigate(`/`, { replace: true });
      let payload = {
        "postId": params.feedid,
        // "commentPage": 1,
        // "commentLimit": 10
      }
      const res = await ApiServices.post(`astrologer/quest/detail`, payload, {
        Authorization: getUserToken()
      });
      if (res.status == 200 && res.data.status == true) {
        setPostData(res.data.data)
        const Data = res.data.data[0];
        
        if (Data?.availability == 2) {
          setIsAvailable(false)
        } else {
          setIsAvailable(true)
        }
      } else {
        console.log("Error:", res);
      }
    }
    fetchData()
  }, [refresh])

 const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if (!comment ||comment === "") 
      newErrors.comment = "Please write answer.";

      return newErrors;
  }; 
  const addAnswer = async () => {

    const newErrors = validateInputs();
    setLoading(true);
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false)
    } else {
        let payload = {
          "postId": params.feedid,
          "answer": comment
        }
        const res = await ApiServices.post(`astrologer/quest/add_answer`, payload, {
          Authorization: getUserToken()
        });
        if (res.status == 200 && res.data.status == true) {
          addToast(res?.data?.message, { appearance: 'success' });
          setComment(''); setRefresh(!refresh)
          setLoading(false)
        } else {
          console.log("Error:", res);
        }
    }
    
  }

  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    console.log(key);
  
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }

  const handleReply = (repData) => {
    document.getElementById('comments').focus();
    setMode(repData);
  }

  const showAllReply = async (data) => {
    let payload = {
      "postId": data?.postId,
      "commentId": data?._id
    }
    const res = await ApiServices.post(`astrologer/post/reply_list`, payload, {
      Authorization: getUserToken()
    });
    if (res.status == 200 && res.data.status == true) {
      setReplyAll(res?.data?.list[0]);
      setExpanded(true)
    } else {
      console.log("Error:", res);
    }
  }

  return (
    <>
      <Header />
      <section className="as_zodiac_single_wrapper astrologer_feed_area">
        <div className="astrologer_heading">
          <div className="container d-flex justify-content-between">
          <h4>
          <ArrowLeft
              className="cursor-pointer"
              style={{marginRight: "15px"}}
              size={25}
              onClick={() => navigate(-1)}/>
             Question/Answer</h4>
            <div className="d-flex align-items-center flex-column flex-lg-row flex-md-row">
            </div>
          </div>
        </div>
        <div className="astrologer_feed_inner mt-3 pb-5">
          <div className="container">
            <div className="row">
              <div className="">
                <div className="astro_feed_sec">
                  <div className="card _feed_box mt-3">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <div className="user_info d-flex align-items-center">
                        <img src={ postData?.userId?.profileImage || '../images/chat_UserImage.jpg'} className="img-fluid user_img" />
                        <div className="ms-2">
                          <h4>{postData?.userId?.name}</h4>
                          <p>{moment(postData?.createdAt).format('MMM Do YYYY, h:mm A')}</p>
                        </div>
                      </div>
                      <div className="fw-medium "> 
                            <span className=" text-capitalize me-2">{postData?.utmSource}</span>
                            <span>
                             {postData?.currency === "INR" ? `₹${postData?.amount}`:`$${postData?.amount}`}
                             </span>
                           </div>
                      {/* <a href="#"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></a> */}
                    </div>
                    <div className="card-body">
                    <div className="detail-card">
                    <ul className="nav question-item">
                     <li className="me-4">
                         {postData?.additionalDetails?.name}
                        </li>
                        <li className="me-4">
                         Date of birth : {moment( postData?.additionalDetails?.dob).format('ll')}
                        </li>
                        <li className="me-4">
                        Birth Time : { moment({hour: postData?.additionalDetails?.hour, minute: postData?.additionalDetails?.min}).format('hh:mm A')}
                       
                        </li>
                        <li className="me-4">
                          Gender : {postData?.additionalDetails?.gender}
                        </li>
                        <li className="">
                        Address : {postData?.additionalDetails?.address}
                        </li>
                      </ul>
                      </div>
                      <p className="text-danger">Question :</p>
                      <p>{postData?.description}</p>
                      <img src={postData?.mediaFile} className="img-fluid" />
                    </div>
                    <div className="card-footer">
                      <div className="feed_comments_sec">
                        <ul>
                          <li>
                         {postData?.status === "Answered" ?<>
                        {/* <div className="card-body"> */}
                       <p className="text-success">Answer :</p>
                        <p style={{color:'#767c90'}}>{moment(postData?.answerDate).format('MMM Do YYYY, h:mm A')}</p>
                        <p>{postData?.answer}</p>
                       <img src={postData?.answerMedia} className="img-fluid" />
                        {/* </div>   */}
                       </>:  <> 
                            <div className="">
                              <textarea className="form-control" name="comment" type="text" placeholder={`Write Answer...`} value={comment} onChange={e => {setComment(e.target.value);handleChange(e)}} id="comments" />
                             <span  className="errorVal"> {errors?.comment}</span>
                            </div>
                            {loading?<button className="as_btn mt-2 ms-0" >Processing...</button>:<button className="as_btn mt-2 ms-0" onClick={(e) => {addAnswer();}}>Submit</button>} 

                           </> }

                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="feeds_requset_sec mt-3">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Feed;